import { Checkbox, Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { observer } from 'mobx-react';
import React, { Component, useEffect, useState } from 'react';

import BorderWrapper from '@components/fw/FloatLabel/BorderWrapper';
import RemoteSelect from '@components/fw/RemoteSelect';

import dictService from '@stores/dictStore/dictStore.service';
import optionsStore from '@stores/optionsStore';

interface Props {
  name: string;
  label: string;
  form: FormInstance;
  'data-test'?: string;
}

interface State {
  visible: boolean;
}

@observer
class GlobalCountyInput extends Component<Props, State> {
  formRef: FormInstance;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: !!optionsStore.request[this.props.name],
    };
  }

  onVisibleChange = (value: boolean) => {
    this.setState({ visible: value });
    this.props.form.resetFields([this.props.label]);
  };

  render() {
    const visible = this.state.visible;
    return (
      <Row gutter={{ xs: 0 }}>
        <Col xs={24} lg={12} style={{ height: 32 }}>
          <Checkbox checked={visible} onChange={(e) => this.onVisibleChange(e.target.checked)}>
            {this.props.label}
          </Checkbox>
        </Col>
        <Col xs={24} lg={12}>
          {visible && (
            <BorderWrapper height={'32px'} position={'bottom'}>
              <Form.Item name={this.props.name} rules={[{ required: true, message: 'Выберите из списка' }]}>
                <RemoteSelect
                  bordered={false}
                  primitiveResult={true}
                  autoInitDict={true}
                  dataSource={dictService.globalCountries}
                  valueExpr={'countryId'}
                  displayExpr={'shortName'}
                  data-test={this.props['data-test']}
                />
              </Form.Item>
            </BorderWrapper>
          )}
        </Col>
      </Row>
    );
  }
}

export default GlobalCountyInput;
